import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  if(action.type === `SET_DATA`) {
    return Object.assign({}, state, {
      data: action.payload
    })
  }

  return state
}


const initialState = { data: {} }

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore